var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "hide-footer": "",
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "ok-variant": "success",
        "cancel-variant": "light",
        title: _vm.rendModalTitle,
      },
      on: { hidden: _vm.handleCloseModal },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", { staticClass: "container-layout px-0 animated fadeIn" }, [
        _vm.supplier
          ? _c(
              "div",
              { staticClass: "form" },
              [
                _c("div", { staticClass: "card-collapsible px-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleCollapsibleHeader(
                            "general-information"
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "title w-90" },
                        [
                          _c("b-icon", {
                            staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                            attrs: { icon: "info-circle" },
                          }),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(
                              _vm._s(_vm.FormMSG(21, "General information"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer d-flex justify-content-end w-10",
                        },
                        [
                          !_vm.configAccordion["general-information"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronDown"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                          _vm.configAccordion["general-information"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronUp"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      visible:
                        _vm.configAccordion["general-information"].isVisible,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content-card-collapsible" },
                      [
                        _c(
                          "b-row",
                          [
                            _vm.filterableByCountry
                              ? _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "6",
                                      xl: "6",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(122332, "Country"),
                                          "label-for":
                                            "supplier-country-filter",
                                        },
                                      },
                                      [
                                        _c("CountrySelector", {
                                          attrs: {
                                            "custom-id":
                                              "supplier-country-filter",
                                            "country-selected":
                                              _vm.countryKeyFilter,
                                          },
                                          on: {
                                            change: _vm.handleCountryChange,
                                          },
                                          model: {
                                            value: _vm.countryKeyFilter,
                                            callback: function ($$v) {
                                              _vm.countryKeyFilter = $$v
                                            },
                                            expression: "countryKeyFilter",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(343, "Name"),
                                      "label-for": "new-supplier-name",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.supplier.name.$error,
                                      },
                                      attrs: {
                                        id: "new-supplier-name",
                                        type: "text",
                                        required: "",
                                        placeholder: _vm.FormMSG(1113, "Name"),
                                      },
                                      model: {
                                        value: _vm.supplier.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.supplier, "name", $$v)
                                        },
                                        expression: "supplier.name",
                                      },
                                    }),
                                    _vm.submitted &&
                                    !_vm.$v.supplier.name.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    65,
                                                    "Please, length should be at least 2"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c("GoogleAutoComplete", {
                                  key: `google_auto_complete_${_vm.countryKeyFilter}`,
                                  ref: "google-search-supplier",
                                  attrs: {
                                    id: "new-supplier-name",
                                    "put-search": _vm.searchAddressFromVat,
                                    label: _vm.FormMSG(1115, "Internet search"),
                                    country:
                                      _vm.googleAutoCompleteRestrictedCountry,
                                  },
                                  on: {
                                    "update:country": function ($event) {
                                      _vm.googleAutoCompleteRestrictedCountry =
                                        $event
                                    },
                                    placechanged: _vm.handleAutoCompleteChange,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c("VatChecker", {
                                  on: { change: _vm.handleVatChange },
                                  model: {
                                    value: _vm.supplier.vat,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.supplier, "vat", $$v)
                                    },
                                    expression: "supplier.vat",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isEditable
                          ? _c(
                              "b-row",
                              {
                                staticClass:
                                  "d-flex justify-content-center mt-1",
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "4",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          block: "",
                                          variant: "outline-danger",
                                        },
                                        on: { click: _vm.handleDeleteSupplier },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(1114, "Delete")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "card-collapsible px-0 mt-3 mb-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleCollapsibleHeader("address")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "title w-90" },
                        [
                          _c("b-icon", {
                            staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                            attrs: { icon: "geo-fill" },
                          }),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(_vm._s(_vm.FormMSG(22, "Address"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer d-flex justify-content-end w-10",
                        },
                        [
                          !_vm.configAccordion["address"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronDown"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                          _vm.configAccordion["address"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronUp"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      visible: _vm.configAccordion["address"].isVisible,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "content-card-collapsible" },
                      [
                        _c("AddressForm", {
                          ref: "supplier-address",
                          attrs: {
                            "auto-completed-data":
                              _vm.handleAutoCompleteAddressFromName,
                            "edit-data": _vm.currAddress,
                            "fill-search": _vm.searchAddress,
                            "filterable-by-country": false,
                            "country-key": _vm.countryKeyFilter,
                            "has-country-key-parent-filter":
                              _vm.filterableByCountry,
                            "form-validation": _vm.$v.addressPayload,
                            "form-submitted": _vm.submitted,
                          },
                          on: {
                            "update:countryKey": function ($event) {
                              _vm.countryKeyFilter = $event
                            },
                            "update:country-key": function ($event) {
                              _vm.countryKeyFilter = $event
                            },
                            "update:formSubmitted": function ($event) {
                              _vm.submitted = $event
                            },
                            "update:form-submitted": function ($event) {
                              _vm.submitted = $event
                            },
                            change: _vm.handleAddress,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "card-collapsible px-0 mt-3 mb-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleCollapsibleHeader("data")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "title w-90" },
                        [
                          _c("b-icon", {
                            staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                            attrs: { icon: "person-lines-fill" },
                          }),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(_vm._s(_vm.FormMSG(23, "Data"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer d-flex justify-content-end w-10",
                        },
                        [
                          !_vm.configAccordion["data"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronDown"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                          _vm.configAccordion["data"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronUp"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-collapse",
                  { attrs: { visible: _vm.configAccordion["data"].isVisible } },
                  [
                    _c(
                      "div",
                      { staticClass: "content-card-collapsible" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  sm: "12",
                                  md: `${
                                    _vm.supplier.companyType !== 0 ? 6 : 12
                                  }`,
                                  lg: `${
                                    _vm.supplier.companyType !== 0 ? 6 : 12
                                  }`,
                                  xl: `${
                                    _vm.supplier.companyType !== 0 ? 6 : 12
                                  }`,
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(16, "Company type"),
                                      "label-for":
                                        "supplier-input-company-type",
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.companyTypes },
                                      on: { change: _vm.handleCompanyType },
                                      model: {
                                        value: _vm.supplier.companyType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.supplier,
                                            "companyType",
                                            $$v
                                          )
                                        },
                                        expression: "supplier.companyType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.supplier.companyType !== 0
                              ? _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      sm: "12",
                                      md: "6",
                                      lg: "6",
                                      xl: "6",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            17,
                                            "Contact name"
                                          ),
                                          "label-for":
                                            "supplier-input-contact-name",
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "supplier-input-contact-name",
                                            type: "text",
                                            required: "",
                                            placeholder: _vm.FormMSG(
                                              18,
                                              "Contact name..."
                                            ),
                                          },
                                          model: {
                                            value: _vm.supplier.contactName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.supplier,
                                                "contactName",
                                                $$v
                                              )
                                            },
                                            expression: "supplier.contactName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "6", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(10, "Phone"),
                                      "label-for": "supplier-input-phone",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "supplier-input-phone",
                                        type: "text",
                                        required: "",
                                        placeholder: _vm.FormMSG(
                                          30,
                                          "Phone..."
                                        ),
                                      },
                                      model: {
                                        value: _vm.supplier.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.supplier, "phone", $$v)
                                        },
                                        expression: "supplier.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "6", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(11, "Email"),
                                      "label-for": "supplier-input-email",
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "supplier-input-email",
                                        type: "email",
                                        required: "",
                                        placeholder: _vm.FormMSG(
                                          29,
                                          "Email..."
                                        ),
                                      },
                                      model: {
                                        value: _vm.supplier.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.supplier, "email", $$v)
                                        },
                                        expression: "supplier.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "card-collapsible px-0 mt-3 mb-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.handleCollapsibleHeader("bank")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "title w-90" },
                        [
                          _c("b-icon", {
                            staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                            attrs: { icon: "bank2" },
                          }),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(_vm._s(_vm.FormMSG(24, "Bank"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer d-flex justify-content-end w-10",
                        },
                        [
                          !_vm.configAccordion["bank"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronDown"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                          _vm.configAccordion["bank"].isVisible
                            ? _c(_vm.getLucideIcon("ChevronUp"), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "b-collapse",
                  { attrs: { visible: _vm.configAccordion["bank"].isVisible } },
                  [
                    _c(
                      "div",
                      { staticClass: "content-card-collapsible" },
                      [
                        _vm.supplier.supplierBankAccounts &&
                        _vm.supplier.supplierBankAccounts.length > 0
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.supplier.supplierBankAccounts,
                                function (bankItem, i) {
                                  return _c(
                                    "b-row",
                                    { key: i },
                                    [
                                      _c(
                                        "b-col",
                                        { staticClass: "p-0 m-0" },
                                        [
                                          _c("bank-form", {
                                            staticClass: "p-0 m-0",
                                            attrs: {
                                              item: bankItem,
                                              index: i,
                                              supplier: {
                                                id: +_vm.supplier.id,
                                                index: 0,
                                              },
                                            },
                                            on: {
                                              "supplier-bank-form:removed":
                                                _vm.removeBank,
                                              "finance-bank-form:updated":
                                                _vm.onLineBankChanged,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "text-center py-2 text-empty-data",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(33, "No data found")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                        _c(
                          "b-row",
                          { staticClass: "pb-3" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "d-flex justify-content-end",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "w-200-px d-flex justify-content-center align-items-center",
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary",
                                      block: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNewBank()
                                      },
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("PlusCircle"), {
                                      tag: "component",
                                      attrs: { size: 18, "stroke-width": 2 },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "px-2",
                                        staticStyle: {
                                          "margin-bottom": "-2px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(34, "Add new bank")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "content_sb_middle_end clearfix full push_t_30" },
        [
          _c(
            "b-button",
            {
              attrs: { pill: "", variant: "light" },
              on: { click: _vm.handleCloseModal },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(2, "Cancel")) + "\n\t\t")]
          ),
          _c(
            "b-button",
            {
              staticClass: "push_l_10",
              attrs: { pill: "", variant: "primary" },
              on: { click: _vm.handleSubmitModal },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Save")) + "\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }